import { ChangeEvent } from "react";

export default function Searchbar({value, placeholder, onChange}: {
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
}) {

    if (!placeholder) placeholder = 'Search...';

    return (
        <div className="join w-full border items-center bg-base-100 focus-within:border-secondary transition-all">
            <div className="join-item px-2 opacity-50"><i className="fas fa-search"></i></div>
            <input type="text" placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} className="input input-sm join-item w-full focus:outline-none border-none" />
            {value !== '' && (
                <button onClick={() => onChange('')} className="join-item btn btn-sm btn-ghost"><i className="fas fa-times"></i></button>
            )}
        </div>
    );
}