import { createContext, useState } from "react";
import { classArray } from "../../../util/helpers.util";
import { Link } from "react-router-dom";

type ITabContext = {
    setSelected: (number) => void;
}

export const PageTabContext = createContext<ITabContext>(undefined);

export function PageTabs({tabs, children}: {
    tabs: {
        label: string;
        to: string;
    }[];
    children: React.ReactNode;
}) {

    const [selected, setSelected] = useState(0);

    return (
        <PageTabContext.Provider value={{
            setSelected
        }}>
            <div className="border-b flex items-center">
                {tabs.map((tab, i) => (
                    <Link key={`tab-label-${tab.label}-${i}`}
                    to={tab.to}
                    className={classArray(
                        'p-2 px-4',
                        selected === i ? 'border-b-2 border-orange-400' : 'hover:border-b'
                    )}
                    >
                        {tab.label}
                    </Link>
                ))}
            </div>

            {children}
        </PageTabContext.Provider>
    );

}