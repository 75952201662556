import { useState } from "react";
import { IUserStyle } from "../../../type/user-style.type";
import Editor from "./components/Editor";
import StylePicker from "./components/StylePicker";

export default function StyleEditor({id, onUpdate}: {
    id: number;
    onUpdate?: (style: IUserStyle) => void;
}) {

    const [selected, setSelected] = useState('Edit');
    const tabs = [
        'Edit',
        'Select'
    ];

    return (
        <div>
            <div className="flex items-center border-b">
                {tabs.map(v => (
                    <button key={`tab-${v}`}
                    onClick={() => setSelected(v)}
                    className={"p-2 px-4" + (v === selected ? ' border-b-2 border-orange-500' : ' hover:border-b-2 border-gray-500')}>{v}</button>
                ))}
            </div>

            {selected === 'Edit' ? <Editor id={id} onUpdate={onUpdate} /> : <StylePicker />}
        </div>
    );

}