import { useContext, useEffect, useState } from "react";
import { IAuthUser, IUser } from "../../../../../type/user.type";
import { AuthContext } from "../../../../contexts/auth.context";
import { SocketContext } from "../../../../contexts/socket.context";
import React from "react";
import { classArray } from "../../../../../util/helpers.util";
import useForm from "../../../../hooks/form.hook";
import { onEnterKey } from "../../../../../util/input.util";
import useApi from "../../../../hooks/api.hook";
import Modal from "../../../../components/Modal";
import { PMSettings } from "../../../../../util/storage.util";
import { IPrivateMessage } from "../../../../../type/message.type";



export default function PrivateMessages({user}: {
    user: IUser;
}) {
    const {auth} = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const pmService = useApi("private-messages/");

    const form = useForm({
        message: '',
        sentColor: PMSettings.GetSentColor() || '',
        receivedColor: PMSettings.GetReceivedColor() || '',
        sentTextColor: PMSettings.GetSentTextColor() || '',
        receivedTextColor: PMSettings.GetReceivedTextColor() || ''
    });

    const [messages, setMessages] = useState<IPrivateMessage[]>([]);
    const addMessage = (message: IPrivateMessage) => {
        setMessages(prev => {
            return [
                message,
                ...prev
            ]
        })
    }

    const SendMessage = () => {
        if (/^\s*$/.test(form.message)) {
            return form.set({message: ''});
        }

        socket.send('send-pm', {
            to: user.id,
            message: form.message
        });

        socket.send('incoming-pm', {to: user.id});

        form.set({message: ''});
    }

    useEffect(() => {
        if (!user || !socket || !auth.user) return;
        const authUser = auth.user as IAuthUser;

        socket.on('rcv-pm', (message: IPrivateMessage) => {
            addMessage(message);
        });

        socket.send('connect-pm', {
            ids: [authUser.sub, user.id]
        })

        return () => {
            socket.off('rcv-pm');

            socket.send('disconnect-pm', {
                ids: [authUser.sub, user.id]
            })
        }

    }, [user, socket, auth.user]);

    useEffect(() => {

        form.submit(async () => {
            const res = await pmService.get(user.id.toString());
            const body = await res.json();

            if (!res.ok) return console.error(body.message);

            setMessages(body.map(v => {
                return {
                    authorId: v.fromId,
                    message: v.message,
                    timestamp: v.createdAt
                }
            }).reverse());
        })

    }, [])

    return (
        <>
            <div className="grid h-full grid-rows-[1fr_auto]">
                <div className="overflow-auto flex flex-col-reverse p-2">
                    {form.loading ? (
                        <div className="h-full flex items-center justify-center">
                            <div>
                                <div className="loading loading-lg"></div>
                            </div>
                        </div>
                    ) : messages.length > 0 ? (
                        <>
                        {messages.map((msg, i) => {
                            const authUser = auth.user as IAuthUser;
                            const timestamp = new Date(msg.timestamp);
                            const hourMin = `${timestamp.getHours()}:${timestamp.getMinutes().toString().padStart(2, '0')}`
                            const timestampPrev = i+1 < messages.length ? new Date(messages[i+1].timestamp) : undefined;
                            const hourMinPrev = timestampPrev ? `${timestampPrev.getHours()}:${timestampPrev.getMinutes().toString().padStart(2, '0')}` : undefined;
                            const styleId = msg.authorId === authUser?.sub ? authUser.activeStyleId : user.activeStyleId;
                            let head: React.ReactNode;
                            // head = `${hourMin} ${hourMinPrev}`
            
                            if (hourMin != hourMinPrev) {
                                head = `${timestamp.toLocaleDateString()} ${hourMin}`
                            }
                            return (
                                <div key={`pm-${user.id}-${i}`} className={classArray(
                                    'chat',
                                    msg.authorId === authUser?.sub ? 'chat-end' : 'chat-start'
                                )}>
                                    <div className="chat-image avatar">
                                        <div className="w-8 rounded-full ring-2"><img src={`/api/v1/users/${msg.authorId}/avatar/${styleId}`} alt="Avatar" /></div>
                                    </div>
                                    {head != undefined && (
                                        <div className="chat-header text-xs">
                                            {head}
                                        </div>
                                    )}
                                    <div className={classArray(
                                        'chat-bubble whitespace-pre-wrap',
                                        msg.authorId === authUser?.sub && 'chat-bubble-secondary'
                                    )}
                                    style={{
                                        backgroundColor: msg.authorId === authUser?.sub ? form.sentColor : form.receivedColor,
                                        color: msg.authorId === authUser?.sub ? form.sentTextColor : form.receivedTextColor
                                    }}
                                    dangerouslySetInnerHTML={{__html: msg.message}}>
                                    </div>
                                </div>
                            );
                        })}
                        </>
                    ) : (<p className="p-2">Messages with {user.username} will appear here.</p>)}
                </div>
                <div>
                    <div className="flex items-center p-2 gap-2">
                        <div className="flex-1">
                            <textarea placeholder="Enter your message and press send"
                            value={form.message} onChange={e => form.set({message: e.target.value})}
                            onKeyDown={e => {
                                if ((e.key) === "Enter") {
                                    if (!e.shiftKey) {
                                        e.preventDefault();
                                        SendMessage();
                                    }
                                }
                            }}
                            className="textarea w-full textarea-bordered"></textarea>
                        </div>
                        <div>
                            <button disabled={/^\s*$/.test(form.message)} onClick={SendMessage} className="btn btn-secondary"><i className="fas fa-paper-plane"></i></button>
                        </div>
                        <div>
                            <label htmlFor="pm_settings_modal" className="btn"><i className="fas fa-cog"></i></label>
                        </div>
                    </div>
                </div>
            </div>

            <Modal id="pm_settings_modal" title="Private Message Settings">
                <div className="bg-base-300 p-4">
                    <div className="chat chat-start">
                        <div className="chat-bubble" style={{
                            backgroundColor: form.receivedColor || undefined,
                            color: form.receivedTextColor || undefined
                        }}>Received Message</div>
                    </div>
                    <div className="chat chat-end">
                        <div className="chat-bubble chat-bubble-secondary" style={{
                            backgroundColor: form.sentColor || undefined,
                            color: form.sentTextColor || undefined
                        }}>Sent Message</div>
                    </div>
                </div>

                <div className="grid gap-4">
                    <div className="flex items-center">
                        <div className="flex-1">Sent Bubble Color</div>
                        <div>
                            <input onChange={e => {
                                const val = e.target.value;
                                PMSettings.SetSentColor(val);
                                form.set({sentColor: val});
                            }} type="color" value={form.sentColor || '#000000'} className="bg-transparent w-16" />
                            <input type="text" value={form.sentColor}
                            onChange={e => {
                                PMSettings.SetSentColor(e.target.value)
                                form.set({sentColor: e.target.value})
                            }}
                            className="input input-sm input-bordered w-24" />
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="flex-1">Sent Text Color</div>
                        <div>
                            <input onChange={e => {
                                const val = e.target.value;
                                PMSettings.SetSentTextColor(val);
                                form.set({sentTextColor: val});
                            }} type="color" defaultValue={PMSettings.GetSentTextColor() || '#000000'} className="bg-transparent w-16" />
                            <input type="text" value={form.sentTextColor}
                            onChange={e => {
                                PMSettings.SetSentTextColor(e.target.value)
                                form.set({sentTextColor: e.target.value})
                            }}
                            className="input input-sm input-bordered w-24" />
                        </div>
                    </div>
                    
                    <div className="flex items-center">
                        <div className="flex-1">Received Bubble Color</div>
                        <div>
                            <input onChange={e => {
                                const val = e.target.value;
                                PMSettings.SetReceivedColor(val);
                                form.set({receivedColor: val});
                            }} type="color" defaultValue={PMSettings.GetReceivedColor() || '#000000'} className="bg-transparent w-16" />
                            <input type="text" value={form.receivedColor}
                            onChange={e => {
                                PMSettings.SetReceivedColor(e.target.value)
                                form.set({receivedColor: e.target.value})
                            }}
                            className="input input-sm input-bordered w-24" />
                        </div>
                    </div>
                    
                    <div className="flex items-center">
                        <div className="flex-1">Received Text Color</div>
                        <div>
                            <input onChange={e => {
                                const val = e.target.value;
                                PMSettings.SetReceivedTextColor(val);
                                form.set({receivedTextColor: val});
                            }} type="color" defaultValue={PMSettings.GetReceivedTextColor() || '#000000'} className="bg-transparent w-16" />
                            <input type="text" value={form.receivedTextColor}
                            onChange={e => {
                                PMSettings.SetReceivedTextColor(e.target.value)
                                form.set({receivedTextColor: e.target.value})
                            }}
                            className="input input-sm input-bordered w-24" />
                        </div>
                    </div>
                </div>

                <div className="modal-action">
                    <button onClick={() => {
                        PMSettings.SetSentColor('');
                        PMSettings.SetReceivedColor('');
                        PMSettings.SetSentTextColor('');
                        PMSettings.SetReceivedTextColor('');
                        form.set({sentColor: '', receivedColor: '', sentTextColor: '', receivedTextColor: ''});
                    }} className="btn">Reset</button>
                    <label htmlFor="pm_settings_modal" className="btn btn-neutral">Close</label>
                </div>
            </Modal>
        </>
    );
}