import { useContext, useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import { IMessage } from "../../../../../type/message.type";
import useApi from "../../../../hooks/api.hook";
import Loading from "../../../../components/Loading";
import Message from "../../../../components/Message";
import { SocketContext } from "../../../../contexts/socket.context";

export default function ManagePostModal({msg, onClose}: {
    msg: IMessage;
    onClose: () => void;
}) {
    const socket = useContext(SocketContext);

    const DeleteMsg = async () => {
        // const res = await api.delete(`messages/${msg.id}`);
        // if (!res.ok) return console.error((await res.json()).message);

        onClose();
        socket.send("delete_msg", {msg});
    }

    const DeleteUserMsgs = async () => {
        // const res = await api.delete(`messages/room/${msg.room}/user/${msg.authorId}`);
        // if (!res.ok) return console.error((await res.json()).message);

        onClose();

        if (!msg.authorId) {
            socket.send("delete_ip_msgs", {
                room: msg.room,
                ip: msg.ip
            });
        } else {
            socket.send("delete_users_msgs", {
                room: msg.room,
                userId: msg.authorId
            });
        }
        
    }

    return (
        <>
        <input type="checkbox" checked className="modal-toggle" />
        <div className="modal">
            <div className="modal-box bg-base-200">
                <div className="flex mb-4 items-center">
                    <div className="flex-1">
                        <p className="font-bold text-lg">Moderate Post</p>    
                    </div>

                    <div>
                        <button onClick={onClose} className="cursor-pointer"><i className="fas fa-times"></i></button>
                    </div>
                </div>

                <div className="border">
                    <Message msg={msg} style={msg.author?.activeStyle} onSelect={undefined} />
                </div>

                <div className="flex items-center gap-2 mt-4">
                    <button onClick={DeleteMsg} className="btn bg-base-300 flex-1">Delete</button>
                    <button onClick={DeleteUserMsgs} className="btn bg-base-300 flex-1">Delete all</button>
                    <button className="btn flex-1 btn-error text-white">Ban</button>
                </div>
            </div>
        </div>
        </>
    );
}