import { createContext, useEffect, useRef, useState } from "react";
import { Random } from "../../util/random.util";

type IToastContext = {
    addToast: (toast: React.ReactNode, action?: React.ReactNode) => void;
}

type IToast = {
    toastId: number;
    action?: React.ReactNode;
    onClose: () => void;
}

type ToastProps = IToast & React.ComponentPropsWithoutRef<"div">;

export const ToastContext = createContext<IToastContext>(undefined);

export function Toast(props: ToastProps) {
    const {action, toastId, onClose, children, ..._props} = props;
    const ref = useRef<HTMLDivElement>();
    const timer = useRef({
        max: 5000,
        current: 0,
        paused: false,
        interval: undefined
    });

    const Close = () => {
        clearInterval(timer.current.interval);
        ref.current.classList.add("close");
        setTimeout(() => {
            onClose();
        }, 500);
    }

    useEffect(() => {
        if (!ref.current) return;

        const tick = () => {

            if (!timer.current.paused) {
                timer.current.current += 1000/20;

                if (timer.current.current >= timer.current.max) {
                    Close();
                }
            } else {
                timer.current.current = 0;
            }
        }

        const mousemove = (e: MouseEvent) => {
            if (!ref.current) return;
            const box = ref.current.getBoundingClientRect();
            const bMouseInBox = e.clientX >= box.left && e.clientX <= box.left + box.width && e.clientY >= box.top && e.clientY <= box.top + box.height;

            timer.current.paused = bMouseInBox;
        }

        window.addEventListener("mousemove", mousemove);

        timer.current.interval = setInterval(tick, 1000/20)

        

        return () => {
            clearInterval(timer.current.interval);
            window.removeEventListener("mousemove", mousemove);
            timer.current.interval = undefined;
            
        }


    }, [ref]);

    return (
        <div {..._props}>
            <div ref={ref} className="Toast slide-up flex items-center gap-2 bg-base-200 border p-2 max-w-sm">
                <div className="flex-1">
                    {children}
                </div>

                <div>
                    {action}
                </div>

                <div onClick={Close} className="cursor-pointer closeBtn absolute z-[1] top-1 right-1 text-sm font-sans">x</div>
            </div>
        </div>
    );
}

export function ToastProvider({children}: {
    children: React.ReactNode;
}) {
    const [toasts, setToasts] = useState<ToastProps[]>([]);
    
    const addToast = (toast: React.ReactNode, action?: React.ReactNode) => {
        const id = Random.range(100000, 999999);
        setToasts(prev => {
            const props: ToastProps = {
                children: toast,
                toastId: id,
                action,
                onClose: () => removeToast(id)
            }
            return [
                ...prev,
                props
            ]
        })
    }

    const removeToast = (id: number) => {
        setToasts(prev => prev.filter(v => v.toastId !== id))
    }

    return (
        <ToastContext.Provider value={{
            addToast
        }}>
            {children}

            <div className="fixed z-[10] bottom-0 right-0 p-4">
                {toasts.map(props => {
                    const {children, ..._props} = props;
                    return (
                        <Toast key={`toast-${props.toastId}`} {..._props} className="my-4">{children}</Toast>
                    );
                })}
            </div>
        </ToastContext.Provider>
    );
}