import { useContext } from "react";
import { UserRank } from "../../../../../enum/user-rank.enum";
import useForm from "../../../../hooks/form.hook";
import { AuthContext } from "../../../../contexts/auth.context";
import FormInput, { PasswordInput } from "../../../../components/FormInput";
import { useNavigate } from "react-router";
import useApi from "../../../../hooks/api.hook";
import Alert from "../../../../components/Alert";
import { emailRegex, adminUsernameRegex } from "../../../../../util/regex.util";

export default function CreateUser() {
    const {auth} = useContext(AuthContext);
    const nav = useNavigate();
    const usersService = useApi("users/");
    const form = useForm({
        email: '',
        username: '',
        password: '',
        rank: UserRank.User
    });

    const Submit = () => {

        const errs: Record<string, string> = {};

        if (!emailRegex.test(form.email)) errs.email = 'Invalid';
        if (!adminUsernameRegex.test(form.username)) errs.username = 'Alphanumeric characters only';
        if (/^\s*$/.test(form.password)) errs.password = 'Cannot be blank';

        if (Object.keys(errs).length > 0) return form.setErrors(errs);

        form.submit(async () => {
            const res = await usersService.post('', {
                ...form
            });

            const body = await res.json();

            if (!res.ok) return form.setErrors(body);

            nav(`/admin/users/${body.id}`);
        })
    }

    return (
        <div>
            <div className="max-w-lg mx-auto my-4">
                <h1 className="font-bold text-3xl">Create User</h1>

                {form.errors.message != undefined && (<Alert onClose={() => form.setError("message", undefined)}>{form.errors.message}</Alert>)}

                <div className="border p-4 my-4">
                    <div className="form-control gap-4">
                        <FormInput label="Email address" error={form.errors.email} value={form.email} onChange={e => form.set({email: e.target.value})} />

                        <FormInput label="Username" error={form.errors.username} value={form.username} onChange={e => form.set({username: e.target.value})} />

                        <PasswordInput label="Password" error={form.errors.password} value={form.password} onChange={e => form.set({password: e.target.value})} />

                        <label>
                            Rank
                            <select value={form.rank.toString()} onChange={e => form.set({rank: parseInt(e.target.value)})} className="select select-bordered w-full">
                                {Object.keys(UserRank).filter(v => !isNaN(parseInt(v))).map(v => {
                                    //@ts-ignore
                                    if (parseInt(v) >= auth.user?.rank) return '';

                                    return (
                                        <option key={`rank-sel-${v}`} value={v}>{UserRank[v]}</option>
                                    );
                                })}
                            </select>
                        </label>

                        <div className="flex items-center justify-end gap-4">
                            <button disabled={form.loading} onClick={() => nav(-1)} className="btn"><i className="fas fa-ban"></i> Cancel</button>
                            <button disabled={form.loading}
                            onClick={Submit}
                            className="btn btn-success">{form.loadText(<i className="fas fa-save"></i>)} Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}