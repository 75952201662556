export default function Navbar({start, center, end}: {
    start?: React.ReactNode;
    center?: React.ReactNode;
    end?: React.ReactNode;
}) {
    return (
        <div className="navbar gap-2">
            <div className="navbar-start flex items-center gap-2">{start}</div>
            <div className="navbar-center flex items-center gap-2">{center}</div>
            <div className="navbar-end flex items-center gap-2">{end}</div>
        </div>
    );
}