import { useEffect, useState } from "react";
import { classArray } from "../../../util/helpers.util";
import React from "react";

export default function LiftedTabs({tabs}: {
    tabs: {
        label: React.ReactNode;
        element: React.ReactNode;
    }[];
}) {
    const [selected, setSelected] = useState(0);
    const id = Math.floor(Math.random() * 100000) + 100000;

    useEffect(() => {
        if (selected >= tabs.length) setSelected(tabs.length-1);
    }, [tabs]);

    return (
        <div className="grid grid-rows-[auto_1fr] h-full p-2">
            <div>
                <div className="tabs tabs-lifted">
                    {tabs.map((tab, i) => (
                        <div key={`tab-${id}-${i}`}
                        onClick={() => setSelected(i)}
                        className={classArray(
                            'tab',
                            selected === i && 'tab-active'
                        )}>{tab.label}</div>
                    ))}
                </div>
            </div>

            <div className="border-l border-r border-b h-full overflow-auto">
                {tabs[selected < tabs.length ? selected : tabs.length-1].element}
            </div>
        </div>
    );
}