import { useContext, useEffect, useState } from "react";
import { UserRank } from "../../../enum/user-rank.enum";
import { IUser } from "../../../type/user.type";
import useApi from "../../hooks/api.hook";
import { AuthContext } from "../../contexts/auth.context";
import { AddPMUser, SetSelectedPMTab } from "../../../util/storage.util";

export default function ProfileCard({user}: {
    user: IUser;
}) {
    const {auth} = useContext(AuthContext);
    const userService = useApi(`users/${user.id}/`);

    const [postCount, setPostCount] = useState<number>(undefined);

    const PMUser = () => {
        if (!auth.user || auth.user.guest) return;

        const i = AddPMUser(user);
        SetSelectedPMTab(i);
        window.location.href = '/pm';

    }

    useEffect(() => {
        userService.get("postCount")
            .then(async res => {
                if (!res.ok) return console.error((await res.json()).message);

                setPostCount(parseInt(await res.text()));
            });
    }, [])

    return (
        <div className="w-64 border rounded-md bg-base-200 backdrop-blur-sm relative">
            <div className="absolute w-full h-24 rounded-t-md"
            style={{
                backgroundColor: user.activeStyle.bgColor
            }}>
                <div className="h-full" style={{
                    backgroundImage: user.activeStyle.bgImage ? `url('/api/v1/users/${user.id}/background/${user.activeStyleId}')` : undefined,
                    opacity: user.activeStyle.bgOpacity,
                    backgroundPosition: user.activeStyle.bgAlign,
                    backgroundSize: user.activeStyle.bgSize || undefined,
                    backgroundRepeat: user.activeStyle.bgRepeat ? 'repeat' : 'no-repeat'
                }}></div>
            </div>

            <div className="text-center m-4 mt-8 relative">
                <div className="avatar"><div className="w-32 rounded-full border-2 bg-base-200"><img src={`/api/v1/users/${user.id}/avatar/${user.activeStyleId}`} alt={user.username} /></div></div>
                <p className="font-bold">{user.activeStyle.displayName} (@{user.username})</p>
                <p className="opacity-50">{UserRank[user.rank]}</p>

                <div className="flex items-center">
                    <div className="flex-1">
                        <p className="text-xl font-bold">{postCount != undefined ? postCount.toLocaleString() : '--'}</p>
                        <p className="opacity-50">Post{postCount !== 1 ? 's' : ''}</p>
                    </div>

                    <div className="flex-1">
                        <p className="font-bold">{new Date(user.createdAt).toLocaleDateString()}</p>
                        <p className="opacity-50">Joined</p>
                    </div>
                </div>

                <div className="flex items-center mt-4 gap-2">
                    {auth.user?.guest === false && auth.user?.sub !== user.id && (
                        <div className="flex-1">
                            <button onClick={PMUser} className="btn btn-sm btn-info w-full"><i className="fas fa-envelope"></i> PM</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}