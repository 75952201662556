import { useEffect, useState } from "react";
import { IUser } from "../../../../../../type/user.type";
import { IUserStyle } from "../../../../../../type/user-style.type";
import Loading from "../../../../../components/Loading";
import useApi from "../../../../../hooks/api.hook";
import LeftTabs from "../../../../../components/Tabs/left";
import Editor from "../../../../../components/StyleEditor/components/Editor";
import { classArray } from "../../../../../../util/helpers.util";

export default function UserStyles({user}: {
    user: IUser;
}) {
    const stylesService = useApi(`users/${user.id}/styles/`);
    const [styles, setStyles] = useState<IUserStyle[]>(undefined);

    useEffect(() => {
        stylesService.get("")
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setStyles(body);
            });
    }, [])

    if (!styles) return (
        <div className="p-4 text-center text-3xl"><i className="fas fa-spinner animate-spin"></i></div>
    );

    return (
        <LeftTabs
        tabs={styles.map((style, i) => {
            const bIsActive = user.activeStyleId === style.id;
            return {
                // label: `Style ${style.id}${style.id === user.activeStyleId ? ' - Active' : ''}`,
                label: (
                    <div className="avatar"><div className={classArray(
                        "w-16 rounded-full ring-2",
                        bIsActive ? 'ring-success' : 'ring-primary'
                    )}><img src={`/api/v1/users/${style.userId}/avatar/${style.id}`} alt={style.id.toString()} /></div></div>
                ),
                element: (
                    <Editor key={`adm-style-edit-${style.id}`} id={style.id} />
                )
            };
        })}
        />
    );
}