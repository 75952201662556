import { Navigate, Route, Routes } from "react-router";

import AdminRoomsPage from "./rooms";
import { PageTabs } from "../../components/PageTabs";
import AdminDashboard from "./dashboard";
import AdminUsers from "./users";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { UserRank } from "../../../enum/user-rank.enum";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import ThemeChangeBtn from "../../components/ThemeChangeBtn";
import Logo from "../../components/Logo";
import Navbar from "../../components/Navbar";

export default function AdminPage() {
    const {auth, logout} = useContext(AuthContext);

    const [allowed, setAllowed] = useState(undefined);

    useEffect(() => {
        if (!auth.token) return setAllowed(false);
        if (!auth.user) return;
        if (auth.user.guest === false && auth.user.rank >= UserRank.Administrator) setAllowed(true);
        else setAllowed(false);
    })

    if (allowed === false) return (<Navigate to={'/'} />)

    if (!allowed) return (<Loading />);

    return (
        <div className="grid h-screen grid-rows-[auto_1fr]">
            <Navbar
            start={(<Link to={'/'}><Logo className="w-12" /></Link>)}
            center={(<ThemeChangeBtn />)}
            end={(<button onClick={logout} className="btn"><i className="fas fa-power-off"></i> Sign out</button>)}
            />

            <div className="overflow-auto">
                <PageTabs tabs={[
                    {
                        label: 'Dashboard',
                        to: '/admin'
                    },
                    {
                        label: 'Rooms',
                        to: '/admin/rooms'
                    },
                    {
                        label: 'Users',
                        to: '/admin/users'
                    },
                ]}>
                    <Routes>
                        <Route path="/" element={<AdminDashboard />} />
                        <Route path="/rooms/*" element={<AdminRoomsPage />} />
                        <Route path="/users/*" element={<AdminUsers />} />
                    </Routes>
                </PageTabs>
            </div>
        </div>
    );

}