export enum UserRank {
    Banned = -1,
    User,
    BetaTester,
    AlphaTester,
    DevTester,
    Donator,
    Moderator,
    Administrator,
    Developer
}