import { useContext, useEffect, useState } from "react";
import { IUser } from "../../../../../../type/user.type";
import { IMessage } from "../../../../../../type/message.type";
import useApi from "../../../../../hooks/api.hook";
import Message from "../../../../../components/Message";
import React from "react";
import { ToastContext } from "../../../../../contexts/toast.context";

export default function UserMessages({user}: {
    user: IUser;
}) {
    const api = useApi("");
    const {addToast} = useContext(ToastContext);

    const [messages, setMessages] = useState<IMessage[]>(undefined);
    const [groups, setGroups] = useState<string[]>(undefined);

    useEffect(() => {
        if (!messages || !messages.length) {
            setGroups([]);
            return;
        }

        const groups = [];

        for (const msg of messages) {
            if (!groups.includes(msg.room)) groups.push(msg.room);
        }

        setGroups(groups);
    }, [messages]);

    useEffect(() => {
        api.get(`users/${user.id}/messages`)
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return addToast(body.message);

                setMessages(body);
            });
    }, [])

    if (messages === undefined || groups === undefined) return (
        <div className="text-center p-8"><div className="loading loading-lg"></div></div>
    );

    if (!messages.length) return (
        <p className="p-8 opacity-50">Nothing to show.</p>
    );

    return (
        <div>
            {groups.map(room => {
                return (
                    <div key={`adm-grp-${room}`} className="border rounded-md m-4 bg-base-200">
                        <div tabIndex={0} className="collapse collapse-arrow">
                            <div className="collapse-title">{room}</div>
                            <div className="collapse-content">
                                <div className="max-h-96 overflow-auto">
                                    {messages.map(msg => {
                                        if (msg.room !== room) return '';
                                        return (
                                            <div key={`adm-msg-${msg.id}`} className="flex items-center gap-4">
                                                <div><div className="badge badge-neutral badge-sm">{msg.id}</div></div>

                                                <div className="flex-1">
                                                    <Message msg={msg} style={user.activeStyle} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}