import { useContext, useEffect, useState } from "react";
import { PageTabContext } from "../../../components/PageTabs";
import useApi from "../../../hooks/api.hook";
import { IUser } from "../../../../type/user.type";
import { UserRank } from "../../../../enum/user-rank.enum";
import { Link, Route, Routes } from "react-router-dom";
import EditUser from "./user";
import CreateUser from "./create";

function Page() {
    const usersService = useApi('users/');

    const [users, setUsers] = useState<IUser[]>();

    useEffect(() => {
        usersService.get('')
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setUsers(body);
            });
    }, [])

    if (!users) return (
        <div>
            <table className="table">
                <thead className="border-b">
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Rank</th>
                        <th>IP</th>
                        <th>Join Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><div className="skeleton w-16 h-4"></div></td>
                        <td><div className="skeleton w-32 h-4"></div></td>
                        <td><div className="skeleton w-64 h-4"></div></td>
                        <td><div className="skeleton w-16 h-4"></div></td>
                        <td><div className="skeleton w-32 h-4"></div></td>
                        <td><div className="skeleton w-64 h-4"></div></td>
                    </tr>
                    <tr>
                        <td><div className="skeleton w-16 h-4"></div></td>
                        <td><div className="skeleton w-32 h-4"></div></td>
                        <td><div className="skeleton w-64 h-4"></div></td>
                        <td><div className="skeleton w-16 h-4"></div></td>
                        <td><div className="skeleton w-32 h-4"></div></td>
                        <td><div className="skeleton w-64 h-4"></div></td>
                    </tr>
                    <tr>
                        <td><div className="skeleton w-16 h-4"></div></td>
                        <td><div className="skeleton w-32 h-4"></div></td>
                        <td><div className="skeleton w-64 h-4"></div></td>
                        <td><div className="skeleton w-16 h-4"></div></td>
                        <td><div className="skeleton w-32 h-4"></div></td>
                        <td><div className="skeleton w-64 h-4"></div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="p-4">

            <Link to={'create'} className="my-4">
                <button className="btn"><i className="fas fa-plus"></i> Create User</button>
            </Link>

            <table className="table border">
                <thead className="border-b">
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Rank</th>
                        <th>IP</th>
                        <th>Join Date</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => {
                        return (
                            <tr key={`adm-user-${user.id}`}>
                                <td><Link to={`/admin/users/${user.id}`} className="underline">{user.id}</Link></td>
                                <td>
                                    <div className="flex items-center gap-2">
                                        <div>
                                            <div className="w-8">
                                                <img src={`/api/v1/users/${user.id}/avatar/${user.activeStyleId}`} alt={`${user.username}`} />
                                            </div>
                                        </div>
                                        <div>
                                            {user.username}
                                        </div>
                                    </div>
                                </td>
                                <td>{user.email}</td>
                                <td>{UserRank[user.rank]}</td>
                                <td>{user.ip}</td>
                                <td>{new Date(user.createdAt).toLocaleString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default function AdminUsers() {
    const tabCtx = useContext(PageTabContext);

    useEffect(() => {
        tabCtx.setSelected(2);
    });

    return (
        <Routes>
            <Route path="/" element={<Page />} />
            <Route path="/create" element={<CreateUser />} />
            <Route path="/:id" element={<EditUser />} />
        </Routes>
    );
}