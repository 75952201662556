import React from "react";
import { classArray } from "../../util/helpers.util";

export type AlertType = 'base-100'|'base-200'|'base-300'|'neutral'|'primary'|'secondary'|'accent'|'success'|'warning'|'error'|'info';

export default function Alert({onClose, type, solid, children}: {
    onClose?: () => void;
    children: React.ReactNode;
    type?: AlertType;
    solid?: boolean;

}) {
    if (!type) type = 'neutral';

    let boxClass, textClass;

    switch (type) {
    
        case 'base-100':
            boxClass = classArray('border-base-100', solid && 'bg-base-100');
            textClass = solid ? 'text-base-content' : 'text-base-100';
            break;
    
        case 'base-200':
            boxClass = classArray('border-base-200', solid && 'bg-base-200');
            textClass = solid ? 'text-base-content' : 'text-base-200';
            break;
    
        case 'base-300':
            boxClass = classArray('border-base-300', solid && 'bg-base-300');
            textClass = solid ? 'text-base-content' : 'text-base-300';
            break;
    
        case 'neutral':
            boxClass = classArray('border-neutral', solid && 'bg-neutral');
            textClass = solid ? 'text-neutral-content' : 'text-neutral';
            break;
    
        case 'primary':
            boxClass = classArray('border-primary', solid && 'bg-primary');
            textClass = solid ? 'text-primary-content' : 'text-primary';
            break;
    
        case 'secondary':
            boxClass = classArray('border-secondary', solid && 'bg-secondary');
            textClass = solid ? 'text-secondary-content' : 'text-secondary';
            break;
    
        case 'accent':
            boxClass = classArray('border-accent', solid && 'bg-accent');
            textClass = solid ? 'text-accent-content' : 'text-accent';
            break;
    
        case 'info':
            boxClass = classArray('border-info', solid && 'bg-info');
            textClass = solid ? 'text-info-content' : 'text-info';
            break;
    
        case 'success':
            boxClass = classArray('border-success', solid && 'bg-success');
            textClass = solid ? 'text-success-content' : 'text-success';
            break;
    
        case 'warning':
            boxClass = classArray('border-warning', solid && 'bg-warning');
            textClass = solid ? 'text-warning-content' : 'text-warning';
            break;
    
        case 'error':
            boxClass = classArray('border-error', solid && 'bg-error');
            textClass = solid ? 'text-error-content' : 'text-error';
            break;
    }

    return (
        <div className={classArray(
            'border rounded p-1 px-2 my-4 flex items-start',
            boxClass
        )}>
            <div className={classArray(
                "flex-1",
                textClass
            )}>
                {children}
            </div>

            {onClose != undefined && (
                <div>
                <button onClick={onClose} className="btn btn-xs"><i className="fas fa-times"></i></button>
            </div>
            )}
        </div>
    );
}