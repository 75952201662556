import { useState } from "react";
import { classArray } from "../../../util/helpers.util";

export default function LeftTabs({tabs, className}: {
    tabs: {
        label: React.ReactNode;
        element: React.ReactNode;
    }[];
    className?: string;
}) {
    const [selected, setSelected] = useState(0);

    return (
        <div className={classArray(
            "grid grid-cols-[auto_1fr]",
            className
        )}>
            <div className="p-2 bg-base-200 overflow-auto">
                <ul className="menu p-0">
                    {tabs.map((tab, i) => (
                        <li key={`tab-${tab.label}-${i}`}
                        onClick={() => setSelected(i)}>
                            <span className={classArray(
                            'p-1',
                            selected === i && 'active'
                            )}>{tab.label}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="p-2 overflow-auto">
                {tabs[selected].element}
            </div>
        </div>
    );
}