import { useContext, useEffect, useState } from "react";
import { PageTabContext } from "../../../components/PageTabs";
import useApi from "../../../hooks/api.hook";
import LeftTabs from "../../../components/Tabs/left";
import Loading from "../../../components/Loading";
import Room from "../../room";
import Page from "../../room/room-page";
import { SocketProvider } from "../../../contexts/socket.context";

export default function AdminRoomsPage() {
    const tabCtx = useContext(PageTabContext);
    const roomService = useApi("messages/rooms/");

    const [rooms, setRooms] = useState<string[]>(undefined);

    useEffect(() => {
        roomService.get('')
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setRooms(body.map(v => v.message_room));
            });
    }, []);

    useEffect(() => {
        tabCtx.setSelected(1);
    });

    if (!rooms) return <Loading />

    return (
        <SocketProvider>
            <div className="bg-base-300 h-full overflow-auto">
                <LeftTabs tabs={rooms.map(room => {
                    return {
                        label: room,
                        element: (
                            <Page key={`adm-room-${room}`} room={room} />
                        )
                    };
                })} className="h-full" />
            </div>
        </SocketProvider>
    );
}