import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Logo from "../../components/Logo";
import ThemeChangeBtn from "../../components/ThemeChangeBtn";
import { FixedTabs } from "../../components/Tabs/FixedTabs";
import React, { useContext, useEffect, useState } from "react";
import UserList from "./components/userlist/search";
import { IAuthUser, IUser } from "../../../type/user.type";
import LiftedTabs from "../../components/Tabs/lifted";
import PrivateMessages from "./components/PrivateMessages";
import { AddPMUser, GetPMUsers, GetSelectedPMTab, SetSelectedPMTab } from "../../../util/storage.util";
import { SocketContext } from "../../contexts/socket.context";
import { AuthContext } from "../../contexts/auth.context";
import useApi from "../../hooks/api.hook";
import { IDBPrivateMessage } from "../../../type/message.type";
import { ToastContext } from "../../contexts/toast.context";

export default function PMPage() {
    const {addToast} = useContext(ToastContext);
    const {auth, logout} = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const usersService = useApi("users/");
    const pmService = useApi("private-messages/");
    const navigate = useNavigate();

    const [users, setUsers] = useState<IUser[]>(GetPMUsers() || []);
    const addUser = (user: IUser) => {
        setUsers(prev => {
            if (prev.map(v => v.id).includes(user.id)) {
                return prev;
            }

            setSelTab(prev.length);

            return [
                ...prev,
                user
            ];
        });
    }
    const removeUser = (user: IUser) => {
        setUsers(prev => {
            return prev.filter(v => v.id !== user.id);
        })
    }

    const [selTab, setSelTab] = useState(GetSelectedPMTab() || 0);

    const addIncoming = async ({id, username}: {
        id: number;
        username: string;
    }) => {
        const _users = GetPMUsers() || [];

        
        if (!_users || _users.map(v => v.id).includes(id)) return;

        const res = await usersService.get(id.toString());
        const body = await res.json();

        if (!res.ok) return console.error(body.message);

        _users.push(body);

        setUsers(_users);
    }

    const [history, setHistory] = useState<{
        user: IUser;
        lastMsg: IDBPrivateMessage;
    }[]>(undefined);
    const [historyMsgs, setHistoryMsgs] = useState<IDBPrivateMessage[]>(undefined);

    useEffect(() => {
        localStorage.setItem("_pmu", JSON.stringify(users));
    }, [users]);

    useEffect(() => {
        if (!auth.token) navigate("/");

        if (!socket || !auth.user) return;
        const authUser = auth.user as IAuthUser;

        socket.on('incoming-pm', addIncoming);

        socket.send('join-group', {group: `pm-${authUser.sub}`});

        return () => {
            socket.off('incoming-pm');
            socket.send('leave-group', {group: `pm-${authUser.sub}`});
        }
    }, [socket, auth.user])

    useEffect(() => {
        (async () => {
            if (!historyMsgs) {
                // setHistory([]);
                return;
            }

            if (historyMsgs.length === 0) {
                setHistory([]);
                return;
            }

            const authUser = auth.user as IAuthUser;

            const groups: {
                user: IUser;
                lastMsg: IDBPrivateMessage;
            }[] = [];

            const add = async (id: number, msg: IDBPrivateMessage) => {
                const res = await usersService.get(`${id}`);
                const body = await res.json();

                if (!res.ok) return addToast(body.message);

                groups.push({
                    user: body,
                    lastMsg: msg
                });
            }

            for (const msg of historyMsgs) {
                if (msg.fromId === authUser?.sub && !groups.map(g => g.user.id).includes(msg.toId)) await add(msg.toId, msg);
                else if (msg.toId === authUser?.sub && !groups.map(g => g.user.id).includes(msg.fromId)) await add(msg.fromId, msg);
            }

            // console.log(groups);

            setHistory(groups);
        })();
    }, [historyMsgs]);

    useEffect(() => {
        if (!auth.user) return;
        const authUser = auth.user as IAuthUser;
        pmService.get(`${authUser.sub}/all`)
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return addToast(body.message);

                setHistoryMsgs(body.reverse());
            });
    }, [auth.user, users])

    return (
        <div className="drawer lg:drawer-open">
            <input type="checkbox" id="pm_drawer" className="drawer-toggle" />
            <div className="drawer-content">
                <div className="grid h-svh grid-rows-[auto_1fr]">
                    <div className="border-b">
                        <Navbar
                        start={(
                            <>
                            <label htmlFor="pm_drawer" className="lg:hidden btn btn-sm btn-outline"><i className="fas fa-bars"></i></label>
                            <Link to={`/`}><Logo className="hidden lg:block w-12" /></Link>
                            </>
                        )}
                        center={<ThemeChangeBtn />}
                        end={(<button onClick={logout} className="btn btn-ghost"><i className="fas fa-power-off"></i></button>)}
                        />
                    </div>

                    <div className="overflow-auto">
                        {users.length > 0 ? (
                            <FixedTabs value={selTab}
                            onChange={(i) => {
                                SetSelectedPMTab(i);
                                setSelTab(i);
                            }}
                            tabs={users.map(user => {
                                return {
                                    label: (
                                        <div className="flex items-center gap-2">
                                            <div>{user.username}</div>

                                            <div>
                                                <button onClick={() => removeUser(user)} className="btn btn-ghost btn-xs"><i className="fas fa-times"></i></button>
                                            </div>
                                        </div>
                                    ),
                                    element: (
                                        <PrivateMessages key={`pm-${user.id}`} user={user} />
                                    )
                                }
                            })} />
                        ) : history == undefined ? (
                            <div className="p-8 text-center">
                                <div className="loading loading-lg"></div>
                            </div>
                        ) : (history.length === 0) ? (
                            <div className="h-full flex items-center justify-center">
                                <div>
                                    <p className="opacity-50">Nothing to display</p>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {history.map(h => {
                                    const authUser = auth.user as IAuthUser;
                                    return (
                                        <div key={`usr-pm-${h.user.id}`} onClick={() => addUser(h.user)} className="hover:bg-base-200 active:bg-neutral active:text-neutral-content cursor-pointer p-2 border-b">
                                            <div className="flex items-center gap-4">
                                                <div>
                                                    <div className="avatar"><div className="w-12 rounded-full ring-2"><img src={`/api/v1/users/${h.user.id}/avatar/${h.user.activeStyleId}`} alt={h.user.username} /></div></div>
                                                </div>

                                                <div className="flex-1">
                                                    <p className="text-lg">{h.user.username}</p>
                                                    <div className="grid grid-cols-[1fr_auto] items-center">
                                                        <div className="flex-1 whitespace-pre overflow-hidden text-ellipsis opacity-50">
                                                            {h.lastMsg.fromId === authUser?.sub ? 'You: ' : ''}{h.lastMsg.message}
                                                        </div>

                                                        <div className="text-xs opacity-50">
                                                            {new Date(h.lastMsg.createdAt).toLocaleString()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    
                </div>
            </div>

            <div className="drawer-side">
                <label htmlFor="pm_drawer" className="drawer-overlay"></label>
                <div className="bg-base-200 w-80 h-full border rounded-md">
                    <div className="lg:hidden flex items-center">
                        <div className="flex-1 p-2">
                            <Link to={'/'}><Logo className="w-12" /></Link>
                        </div>

                        <div>
                            <label htmlFor="pm_drawer" className="btn btn-sm"><i className="fas fa-times"></i></label>
                        </div>
                    </div>

                    <FixedTabs defaultValue={1} tabs={[
                        {
                            label: 'Friends',
                            element: (<div className="p-4 opacity-50">Coming soon</div>)
                        },
                        {
                            label: 'Search',
                            element: (<UserList onSelect={addUser} />)
                        }
                    ]} />
                </div>
            </div>
        </div>

        
    )
}