import { useContext, useEffect, useState } from "react";
import { IUser } from "../../../../../../type/user.type";
import { IDBPrivateMessage } from "../../../../../../type/message.type";
import useApi from "../../../../../hooks/api.hook";
import { classArray } from "../../../../../../util/helpers.util";
import { ToastContext } from "../../../../../contexts/toast.context";

export default function UserPms({user}: {
    user: IUser;
}) {
    const api = useApi("");
    const {addToast} = useContext(ToastContext);

    const [messages, setMessages] = useState<IDBPrivateMessage[]>(undefined);
    const [groups, setGroups] = useState<IUser[]>(undefined);

    useEffect(() => {
        (async () => {
            if (!messages || !messages.length) {
                setGroups([]);
                return;
            }

            const groups: IUser[] = [];

            const add = async (id: number) => {
                const res = await api.get(`users/${id}`);
                const body = await res.json();

                if (!res.ok) return addToast(body.message);

                groups.push(body);
            }

            for (const msg of messages) {
                if (msg.fromId === user.id && !groups.map(g => g.id).includes(msg.toId)) await add(msg.toId);
                else if (msg.toId === user.id && !groups.map(g => g.id).includes(msg.fromId)) await add(msg.fromId);
            }

            setGroups(groups);
        })();
    }, [messages]);

    useEffect(() => {
        api.get(`private-messages/${user.id}/all`)
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return addToast(body.message);

                setMessages(body);
            });
    }, [])

    if (messages === undefined || groups === undefined) return (
        <div className="text-center p-8"><div className="loading loading-lg"></div></div>
    );

    return messages.length > 0 ? (
        <div className="p-4">
            {groups.map(target => {
                return (
                    <div key={`adm-pm-${target.id}`} className="collapse collapse-arrow bg-base-200 border rounded-md my-4" tabIndex={0}>
                        <div className="collapse-title flex items-center gap-4"><div className="avatar"><div className="w-8"><img src={`/api/v1/users/${target.id}/avatar/${target.activeStyleId}`} alt="Avatar" /></div></div> {target.username}</div>
                        <div className="collapse-content">
                            <div className="max-h-96 overflow-auto p-2">
                                {messages.map(msg => {
                                    if (target.id !== msg.fromId && target.id !== msg.toId) return '';

                                    const avStyle = target.id === msg.fromId ? target.activeStyleId : user.activeStyleId

                                    return (
                                        <div className={classArray(
                                            'chat',
                                            msg.fromId === target.id ? 'chat-start' : 'chat-end'
                                        )}>
                                            <div className="chat-image avatar">
                                                <div className="w-8 rounded-full ring-2"><img src={`/api/v1/users/${msg.fromId}/avatar/${avStyle}`} alt="Avatar" /></div>
                                            </div>
                                            <div className="chat-header text-xs">{new Date(msg.createdAt).toLocaleString()}</div>
                                            <div className={classArray(
                                                'chat-bubble',
                                                msg.fromId === target.id ? undefined : 'chat-bubble-secondary'
                                            )}
                                            dangerouslySetInnerHTML={{__html: msg.message}}></div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    ) : (
        <p className="p-8 opacity-50">Nothing to show</p>
    );
}