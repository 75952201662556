import { useContext } from "react";
import { Theme, ThemeContext } from "../../contexts/theme.context";

export default function ThemeChangeBtn() {
    const theme = useContext(ThemeContext);

    return (
        <label className="swap swap-rotate text-lg">
            <input defaultChecked={theme.theme === Theme.DARK} onChange={e => {
                theme.setTheme(e.target.checked ? Theme.DARK : Theme.LIGHT)
            }} type="checkbox" />

            <div className="swap-on"><i className="fas fa-moon"></i></div>
            <div className="swap-off"><i className="fas fa-sun"></i></div>
        </label>
    );
}