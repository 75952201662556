import { useContext, useEffect, useRef, useState } from "react";
import { IMessage } from "../../type/message.type";
import { AuthContext } from "../contexts/auth.context";
import { UserRank } from "../../enum/user-rank.enum";
import { IUserStyle } from "../../type/user-style.type";
import { IUser } from "../../type/user.type";

const RankIcons = {
    [UserRank.BetaTester]: (<span className="text-white" style={{
        textShadow: '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black'
    }}>&beta;</span>),
    [UserRank.AlphaTester]: (<span className="text-white" style={{
        textShadow: '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black'
    }}>&alpha;</span>),
    [UserRank.DevTester]: (<i className="text-white fas fa-cat" style={{
        textShadow: '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black'
    }}></i>),
    [UserRank.Donator]: (<i className="text-yellow-400 fas fa-sack-dollar" style={{
        textShadow: '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black'
    }}></i>),
    [UserRank.Moderator]: (<i className="text-yellow-300 fas fa-shield" style={{
        textShadow: '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black'
    }}></i>),
    [UserRank.Administrator]: (<i className="text-yellow-300 fas fa-chess-queen" style={{
        textShadow: '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black'
    }}></i>),
    [UserRank.Developer]: (<i className="text-yellow-300 fas fa-code" style={{
        textShadow: '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black'
    }}></i>),
}


export default function Message({msg, style, onSelect, onProfileOpen}: {
    msg: IMessage;
    style: IUserStyle;
    onSelect?: (message: IMessage) => void;
    onProfileOpen?: (user: IUser, pos: {x: number; y: number;}) => void;
}) {
    const {auth} = useContext(AuthContext);
    const msgRef = useRef<HTMLDivElement>();

    const time = new Date(msg.createdAt);
    let timestamp = time.toDateString().substring(0, time.toDateString().length-5) + ' ' + time.toLocaleTimeString();

    const onContextMenu = (e: MouseEvent) => {
        if (onSelect == undefined) return;
        e.preventDefault();
        onSelect(msg);
    }

    

    

    // const ClearRoom = async () => {
    //     // const res = await api.delete(`messages/room/${msg.room}`);
    //     // if (!res.ok) console.error((await res.json()).message);
    //     if (onDeleted) onDeleted(msg.id);
    //     socket.send("clear_room", {room: msg.room});
    // }

    useEffect(() => {
        if (!msgRef.current || !auth.user) return;

        if (auth.user.guest === true || auth.user.rank < UserRank.Moderator) return;

        const el = msgRef.current;
        el.addEventListener("contextmenu", onContextMenu);

        return () => {
            el.removeEventListener("contextmenu", onContextMenu);
        }
    }, [auth.user])

    

    if (!auth.user) return (
        <div className="border-b p-1 flex gap-1">
        </div>
    );
    
    return (
        <div ref={msgRef} className="Message border-b relative">
            {msg.author ? (
                <div className="relative" style={{
                    backgroundColor: style.bgColor
                }}>
                    <div className="absolute w-full h-full" style={{
                        backgroundImage: style.bgImage ? `url('${style.bgImage}')` : undefined,
                        backgroundPosition: style.bgAlign,
                        backgroundSize: style.bgSize,
                        backgroundRepeat: style.bgRepeat ? 'repeat' : 'no-repeat',
                        opacity: style.bgOpacity
                    }}></div>
                    <div className="absolute top-0 right-0 text-right z-[1]" style={{fontSize: '10px'}}>
                        <p><span className="bg-black text-white p-[1px] opacity-30">{timestamp}</span></p>
                        {auth.user?.guest === false && auth.user?.rank >= UserRank.Moderator && (
                            <p><span className="bg-black text-white p-[1px] opacity-30">{msg.ip}</span></p>
                        )}
                    </div>
                    <div className="relative p-1">
                        <div className="avatar"><img src={`/api/v1/users/${msg.author.id}/avatar/${style.id}`} alt={msg.author.username} /></div> {RankIcons[msg.author.rank]} <strong onClick={e => {
                            if (!onProfileOpen) return;
                            onProfileOpen(msg.author, {x: e.clientX, y: e.clientY});
                        }}
                        className={onProfileOpen ? 'cursor-pointer' : undefined}
                        style={{color: style.nameColor}}>{style.displayName || msg.author.username}:</strong> <span dangerouslySetInnerHTML={{__html: msg.content}} style={{color: style.fontColor, fontFamily: style.fontFamily, fontSize: style.fontSize}}></span>
                    </div>
                </div>
            ) : (
                <div className="relative">
                    <div className="absolute top-0 right-0 text-right" style={{fontSize: '9px'}}>
                        <p><span className="bg-black text-white p-[1px] opacity-30">{timestamp}</span></p>
                        {auth.user.guest === false && auth.user.rank >= UserRank.Moderator && (
                            <p><span className="bg-black text-white p-[1px] opacity-30">{msg.ip}</span></p>
                        )}
                    </div>
                    <div className="relative">
                        <span className="p-1" dangerouslySetInnerHTML={{__html: msg.content}}></span>
                    </div>
                </div>
            )}
        </div>
    );
}