import { useContext, useEffect, useState } from "react";
import { IUserStyle } from "../../../../../type/user-style.type";
import Loading from "../../../Loading";
import useApi from "../../../../hooks/api.hook";
import { AuthContext } from "../../../../contexts/auth.context";
import Message from "../../../Message";
import { IUser } from "../../../../../type/user.type";

export default function StylePicker() {
    const userService = useApi("users/");
    const {auth, setToken} = useContext(AuthContext);
    const [styles, setStyles] = useState<IUserStyle[]>(undefined);
    const [user, setUser] = useState<IUser>(undefined);

    const createStyle = async () => {
        const res = await userService.post('styles');
        const body = await res.json();

        if (!res.ok) return console.error(body.message);

        setStyles(prev => [...prev, body]);
    }

    const setActiveStyle = async (id: number) => {
        const res = await userService.post(`set-style/${id}`);

        if (!res.ok) return console.error((await res.json()).message);

        const {token} = await res.json();

        setToken(token);
    }

    const deleteStyle = async (id: number) => {
        if (id === user.activeStyleId) return;

        const res = await userService.delete(`styles/${id}`);

        if (!res.ok) return console.error((await res.json()).message);

        setStyles(prev => {
            const li: IUserStyle[] = [];

            for (const style of prev) {
                if (style.id !== id) li.push({...style});
            }

            return li;
        })
    }

    useEffect(() => {
        if (!auth.user || auth.user.guest === true) return;

        userService.get("styles")
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setStyles(body);
            });

        userService.get(auth.user.sub.toString())
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setUser(body);
            });
    }, [auth.user]);

    if (!styles || !user) return (<Loading />)

    return (
        <div className="py-4">

            <div className="my-2 flex items-center gap-4">
                {styles.length < 5 && (
                    <button onClick={createStyle} className="btn"><i className="fas fa-plus"></i> New Style</button>
                )}

                <div>
                    <p>{styles.length}/5</p>
                </div>
            </div>

            {styles.map(style => {

                const bIsActive: boolean = style.id === user.activeStyleId;

                return (
                    <div key={`sel-style-${style.id}`} className="flex items-center gap-2">
                        <div
                        onClick={() => {
                            setActiveStyle(style.id);
                        }}
                        className={[
                            'my-2 flex-1',
                            bIsActive ? 'border-2 border-green-500' : 'hover:border-yellow-500 hover:border cursor-pointer'
                        ].join(" ")}>
                            <Message msg={{
                                author: user,
                                content: 'The quick brown fox jumps over the lazy dog.',
                                createdAt: new Date().toISOString(),
                                room: '',
                                id: -(Math.floor(Math.random()*100000) + 100000),
                                ip: ''
                            }} style={style} />
                        </div>

                        {!bIsActive && (
                            <div>
                                <button onClick={() => deleteStyle(style.id)} className="btn"><i className="fas fa-times"></i></button>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}