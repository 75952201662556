import { useState } from "react"

type IFormHook = {

}

export default function useForm<T>(initialValue: T) {
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState<T>(initialValue);
    const set = (newState: Partial<T>) => {
        setState(prev => ({
            ...prev,
            ...newState
        }))
    }

    const [errors, setErrors] = useState<Record<string, any>>({});
    const setError = (key: string, value: any) => {
        setErrors(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const submit = (callback: () => Promise<void>) => {
        setErrors({});
        setLoading(true);
        callback()
            .finally(() => {
                setLoading(false);
            });
    }

    const loadText = (value: React.ReactNode) => {
        return loading ? <div className="inline-block"><i className="fas fa-spinner animate-spin"></i></div> : value;
    }

    return {
        ...state,
        loading,
        errors,
        set,
        submit,
        loadText,
        setErrors,
        setError
    }
    
}