import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { IUser } from "../../../../../type/user.type";
import { IUserStyle } from "../../../../../type/user-style.type";
import useApi from "../../../../hooks/api.hook";
import Loading from "../../../../components/Loading";
import { UserRank } from "../../../../../enum/user-rank.enum";
import Modal from "../../../../components/Modal";
import LiftedTabs from "../../../../components/Tabs/lifted";
import UserStyles from "./components/UserStyles";
import { AuthContext } from "../../../../contexts/auth.context";
import UserInfo from "./components/UserInfo";
import UserMessages from "./components/UserMessages";
import UserPms from "./components/UserPms";

export default function EditUser() {
    const params = useParams();
    const usersService = useApi("users/");
    const {auth} = useContext(AuthContext);

    const [user, setUser] = useState<IUser>();

    useEffect(() => {

        usersService.get(params.id)
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setUser(body);
            })

    }, [params.id])

    if (!user) {
        return <Loading />
    }

    return (

        <div>

            <div className="max-w-screen-lg mx-auto my-4 pl-8">
                <h1><span className="opacity-50">Edit User</span><br /><span className="text-3xl font-bold">{user.username}</span></h1>

            </div>

            {auth.user?.guest === false && auth.user?.sub !== user.id && auth.user?.rank <= user.rank && (
                <div className="border border-error p-2 rounded text-error max-w-screen-lg mx-auto my-4"><i className="fas fa-times"></i> You cannot edit this user</div>
            )}
        
            <div className="m-4 max-w-screen-lg mx-auto">

                <LiftedTabs tabs={[
                    {
                        label: "Information",
                        element: (
                            <UserInfo user={user} onUpdate={_user => setUser(_user)} />
                        )
                    },
                    {
                        label: "User Styles",
                        element: (
                            <UserStyles user={user} />
                        )
                    },
                    {
                        label: "Messages",
                        element: (
                            <UserMessages user={user} />
                        )
                    },
                    {
                        label: "Private Messages",
                        element: (
                            <UserPms user={user} />
                        )
                    },
                ]} />
                
            </div>
        </div>
    );
}