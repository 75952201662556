import { createContext, useEffect, useState } from "react";
import { classArray } from "../../../util/helpers.util";
import React from "react";

export function FixedTabs({tabs, defaultValue, value, onChange}: {
    tabs: {
        label: React.ReactNode;
        element: React.ReactNode;
    }[];
    defaultValue?: number;
    value?: number;
    onChange?: (index: number) => void;
}) {

    const [selected, setSelected] = useState(value != undefined ? value : (defaultValue != undefined ? defaultValue : 0));
    const id = Math.floor(Math.random() * 100000) + 100000;

    useEffect(() => {
        if (selected >= tabs.length) setSelected(tabs.length-1);
    }, [tabs])

    useEffect(() => {
        if (value == undefined) return;
        setSelected(value);
    }, [value])

    return (
        <div className="grid grid-rows-[auto_1fr] h-full">
            <div>
                <div className="flex items-end border-b">
                    {tabs.map((tab, i) => {
                        return (
                            <div key={`tab-${id}-${i}`}
                            onClick={() => {
                                if (onChange) onChange(i);
                                setSelected(i);
                            }}
                            className={classArray(
                                'p-2 px-4 cursor-pointer',
                                selected === i ? 'border-b-2 border-b-primary' : 'hover:border-b border-b-neutral'
                            )}>
                                {tab.label}
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="overflow-auto">
                {tabs[selected < tabs.length ? selected : tabs.length-1].element}
            </div>
        </div>
    );

}