import { useContext, useEffect, useState } from "react";
import { UserRank } from "../../../../../../enum/user-rank.enum";
import { IUser } from "../../../../../../type/user.type";
import Alert from "../../../../../components/Alert";
import Modal from "../../../../../components/Modal";
import useForm from "../../../../../hooks/form.hook";
import useApi from "../../../../../hooks/api.hook";
import { AuthContext } from "../../../../../contexts/auth.context";
import React from "react";
import { useNavigate } from "react-router";

export default function UserInfo({user, onUpdate}: {
    user: IUser;
    onUpdate?: (user: IUser) => void;
}) {
    const form = useForm<Partial<IUser>>({...user});
    const userService = useApi(`users/${user.id}/`);
    const {auth} = useContext(AuthContext);
    const nav = useNavigate();
    
    const [postCount, setPostCount] = useState<number>(undefined);

    const bCanEdit = auth.user?.guest === false && (auth.user?.sub === user.id || auth.user?.rank > user.rank);

    const Save = () => {
        form.submit(async () => {
            const res = await userService.patch('', {
                username: form.username,
                email: form.email,
                rank: form.rank
            });
            const body = await res.json();

            console.log(body);

            if (!res.ok) return form.setError("message", body.message);

            onUpdate(body);
        })
    }

    const DeleteUser = () => {
        form.submit(async () => {
            const res = await userService.delete('');

            if (!res.ok) return form.setError("delete", (await res.json()).message);

            nav('/admin/users');
        });
    }

    useEffect(() => {
        userService.get("postCount")
            .then(async res => {
                if (!res.ok) return console.error((await res.json()).message);

                setPostCount(parseInt(await res.text()));
            });
    }, []);

    return (
        <div className="p-4">

            {form.errors.message && (
                <Alert onClose={() => form.setError("message", undefined)}>{form.errors.message}</Alert>
            )}

            <div className="grid md:grid-cols-[1fr_auto] gap-4">
                <div className="grid gap-4">
                    <div className="flex items-center gap-4">
                        <label>
                            <p>ID</p>
                            <input disabled type="text" value={user.id} className="input input-bordered w-16 text-right" />
                        </label>

                        <label className="flex-1">
                            <p>Username</p>
                            <input disabled={form.loading || !bCanEdit} type="text" value={form.username} onChange={e => form.set({username: e.target.value})} className="input input-bordered w-full" />
                        </label>
                    </div>
                    
                    <label>
                        <p>Email Address</p>
                        <input disabled={form.loading || !bCanEdit} type="text" value={form.email} onChange={e => form.set({email: e.target.value})} className="input input-bordered w-full" />
                    </label>
                    
                    <label>
                        <p>Rank</p>
                        <select disabled={form.loading || !bCanEdit} value={form.rank.toString()} onChange={e => form.set({rank: parseInt(e.target.value)})} className="select input-bordered w-full">
                            {Object.keys(UserRank).filter(v => !isNaN(parseInt(v))).map(v => (
                                <option key={`rank-sel-${v}`} value={v}>{UserRank[v]}</option>
                            ))}
                        </select>
                    </label>
                </div>

                <div>
                    <div className="w-32 mx-auto"><img src={`/api/v1/users/${user.id}/avatar/${user.activeStyleId}`} alt="" /></div>
                    
                    <div className="my-4 text-sm">
                        <div className="flex items-center gap-4">
                            <div className="flex-1 opacity-50">IP</div>
                            <div className="text-right">{user.ip}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div className="flex-1 opacity-50">Posts</div>
                            <div className="text-right">{postCount != undefined ? postCount.toLocaleString() : '--'}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div className="flex-1 opacity-50">Email Verified</div>
                            <div className="text-right">{user.emailVerifiedAt ? new Date(user.emailVerifiedAt).toLocaleString() : 'No'}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div className="flex-1 opacity-50">Join Date</div>
                            <div className="text-right">{new Date(user.createdAt).toLocaleString()}</div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div className="flex-1 opacity-50">Last Updated</div>
                            <div className="text-right">{user.modifiedAt ? new Date(user.modifiedAt).toLocaleString() : 'Never'}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center gap-4 justify-end mt-4">
                <button disabled={form.loading} onClick={() => window.history.back()} className="btn btn-ghost"><i className="fas fa-ban"></i> Cancel</button>
                {bCanEdit && (
                    <label htmlFor="delete_user_modal" className="btn btn-error"><i className="fas fa-times"></i> Delete</label>
                )}
                <button disabled={form.loading || !bCanEdit}
                onClick={Save}
                className="btn btn-success">{form.loadText(<><i className="fas fa-save"></i> Save</>)}</button>
            </div>

            <Modal id="delete_user_modal" title={`Delete User ${user.username}`}>
                {form.errors.delete != undefined && (
                    <Alert onClose={() => form.setError("delete", undefined)}>{form.errors.delete}</Alert>
                )}
                <p>This will <strong>permanently</strong> delete the user, including all messages, styles, and uploads.</p>
                <div className="modal-action">
                    <label htmlFor="delete_user_modal" className="btn btn-ghost"><i className="fas fa-ban"></i> Cancel</label>
                    <button disabled={form.loading}
                    onClick={DeleteUser}
                    className="btn btn-error">{form.loadText(<i className="fas fa-times"></i>)} Delete</button>
                </div>
            </Modal>
        </div>
    );
}