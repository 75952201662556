import { IUser } from "../type/user.type";

export function SetSelectedPMTab(n: number) {
    const users = GetPMUsers();

    if (!users) return;

    localStorage.setItem("_pms", Math.max(0, Math.min(users.length-1, n)).toString());
}

export function GetSelectedPMTab() {
    const sSel = localStorage.getItem("_pms");

    if (!sSel) return;

    return parseInt(sSel);
}

export function GetPMUsers(): IUser[] {
    const sUserData = localStorage.getItem("_pmu");

    if (!sUserData) return;

    return JSON.parse(sUserData);
}

export function AddPMUser(user: IUser) {
    const users = GetPMUsers() || [];
    
    if (!users.map(u => u.id).includes(user.id)) {
        users.push(user);
        localStorage.setItem("_pmu", JSON.stringify(users));
        return users.length-1;
        // localStorage.setItem("_pms", users.length.toString());
    }

    for (let i = 0; i < users.length; i++) {
        const u = users[i];
        if (u.id === user.id) return i;
    }
}

export function RemovePMUser(user: IUser) {
    let users = GetPMUsers();

    if (!users) return;

    users = users.filter(u => u.id !== user.id);
    localStorage.setItem("_pmu", JSON.stringify(users));
    // localStorage.setItem("_pms", users.length.toString());
}

export function ResetPMUsers() {
    localStorage.removeItem("_pmu");
    localStorage.removeItem("_pms");
}

export namespace PMSettings {
    export function GetSentColor() {
        return localStorage.getItem("_pmcol1")
    }
    export function GetReceivedColor() {
        return localStorage.getItem("_pmcol2")
    }
    export function SetSentColor(color: string) {
        return localStorage.setItem("_pmcol1", color);
    }
    export function SetReceivedColor(color: string) {
        return localStorage.setItem("_pmcol2", color);
    }

    export function GetSentTextColor() {
        return localStorage.getItem("_pmtcol1")
    }
    export function GetReceivedTextColor() {
        return localStorage.getItem("_pmtcol2")
    }
    export function SetSentTextColor(color: string) {
        return localStorage.setItem("_pmtcol1", color);
    }
    export function SetReceivedTextColor(color: string) {
        return localStorage.setItem("_pmtcol2", color);
    }
}