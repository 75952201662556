import React, { createContext, useEffect, useState } from "react";

export enum Theme {
    DARK="dark",
    LIGHT="light"
}

export type IThemeContext = {
    theme: string;
    setTheme: (theme: Theme) => void;
}

const initialValues: IThemeContext = {
    theme: localStorage.getItem("_theme") || Theme.DARK,
    setTheme: undefined,
}

export const ThemeContext = createContext<IThemeContext>(initialValues);

export function ThemeContextProvider({children}: {
    children: React.ReactNode;
}) {
    const [theme, setTheme] = useState<string>(initialValues.theme);
    const SetTheme = (theme: string) => {
        setTheme(theme);
        localStorage.setItem("_theme", theme);
    }

    useEffect(() => {
        document.body.parentElement.dataset.theme = theme;
    }, [theme])

    return <ThemeContext.Provider value={{
        theme,
        setTheme: SetTheme
    }}>
        {children}
    </ThemeContext.Provider>
}