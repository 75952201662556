import { useState } from "react";
import { classArray } from "../../util/helpers.util";

export default function FormInput({label, value, error, className, onChange}: {
    label: string;
    value: string;
    error?: string;
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <label>
            <p>{label} {error ? <span className="text-error text-xs ml-4">{error}</span> : ''}</p>
            <input type="text" value={value} onChange={onChange} className={classArray(
                'input input-bordered w-full',
                className,
                error != undefined && 'input-error'
            )} />
        </label>
    );
}

export function PasswordInput({label, value, error, className, onChange}: {
    label: string;
    value: string;
    error?: string;
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    const [show, setShow] = useState(false);

    return (
        <label>
            <p>{label} {error ? <span className="text-error text-xs ml-4">{error}</span> : ''}</p>
            <div className="join w-full border rounded">
                <input type={show ? 'text' : 'password'} value={value} onChange={onChange} className={classArray(
                    'input w-full',
                    className,
                    error != undefined && 'input-error'
                )} />

                <button onClick={() => setShow(prev => !prev)} className="btn btn-ghost"><i className={`fas ${show ? 'fa-eye' : 'fa-eye-slash'}`}></i></button>
            </div>
        </label>
    );
}