import { useContext, useEffect, useState } from "react";
import { IUser } from "../../../../../../type/user.type";
import useApi from "../../../../../hooks/api.hook";
import useForm from "../../../../../hooks/form.hook";
import Loading from "../../../../../components/Loading";
import React from "react";
import { AuthContext } from "../../../../../contexts/auth.context";
import Searchbar from "../../../../../components/Searchbar";

export default function UserList({onSelect}: {
    onSelect?: (user: IUser) => void;
}) {
    const api = useApi("");
    const {auth} = useContext(AuthContext);
    const form = useForm({
        search: ''
    });

    const [users, setUsers] = useState<IUser[]>(undefined);
    const [searchResults, setSearchResults] = useState<IUser[]>(undefined);

    useEffect(() => {
        if (!users) return;

        if (!form.search) {
            setSearchResults(users);
            return;
        }

        setSearchResults(users.filter(u => u.username.toLowerCase().includes(form.search.toLowerCase())));
    }, [form.search, users]);

    useEffect(() => {
        if (!auth.user) return;
        let authId: number;

        if (auth.user?.guest === true) return;
        else authId = auth.user?.sub;

        api.get("users")
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return form.setError("message", body);



                setUsers(body.filter(usr => usr.id !== authId));
            });
    }, [auth.user])

    if (!users || !searchResults) return (
        <div>
            <Loading />
        </div>
    );

    return (
        <>
        <div className="p-2">
            <Searchbar value={form.search} onChange={v => form.set({search: v})} />
        </div>

        {searchResults.length === 0 ? (<div className="p-4 opacity-50">No results</div>) : searchResults.map((user, i) => {
            return (
                <div key={`usr-${user.id}`} onClick={() => {
                    if (onSelect) onSelect(user);
                }} className="hover:border-2 hover:border-primary cursor-pointer">
                    <div  className="border-b p-1 relative"
                    style={{
                        backgroundColor: user.activeStyle.bgColor,
                        color: user.activeStyle.fontColor,
                    
                    }}>
                        <div style={{
                            backgroundImage: user.activeStyle.bgImage ? `url('/api/v1/users/${user.id}/background/${user.activeStyleId}')` : undefined,
                            backgroundPosition: user.activeStyle.bgAlign,
                            backgroundSize: user.activeStyle.bgSize || undefined,
                            backgroundRepeat: user.activeStyle.bgRepeat ? 'repeat' : 'no-repeat',
                            opacity: user.activeStyle.bgOpacity
                        }} className="absolute top-0 left-0 w-full h-full"></div>

                        

                        <div className="flex items-center gap-2 relative">
                            <div className="avatar"><div className="w-12"><img src={`/api/v1/users/${user.id}/avatar/${user.activeStyleId}`} alt={user.username} /></div></div>
                            <p className="font-bold">{user.username}</p>
                        </div>
                    </div>
                </div>
            );
        })}
        </>
    );
}