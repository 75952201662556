import { useContext, useEffect, useState } from "react";
import { PageTabContext } from "../../../components/PageTabs";
import { VERSION } from "../../../../config/config";
import useApi from "../../../hooks/api.hook";
import { IUser } from "../../../../type/user.type";
import { IMessage } from "../../../../type/message.type";
import Logo from "../../../components/Logo";
import Alert from "../../../components/Alert";
import ProfileCard from "../../../components/ProfileCard";
import { Toast } from "../../../contexts/toast.context";

export default function AdminDashboard() {
    const tabCtx = useContext(PageTabContext);
    const api = useApi("");

    const [apiVer, setApiVer] = useState('--');
    const [users, setUsers] = useState<IUser[]>(undefined)
    const [msgs, setMsgs] = useState<number>(undefined)

    useEffect(() => {
        tabCtx.setSelected(0);
    });

    useEffect(() => {
        api.get('')
            .then(async res => {
                if (!res.ok) return;

                setApiVer(await res.text());
            });
        api.get('users')
            .then(async res => {
                if (!res.ok) return;

                setUsers(await res.json());
            });
        api.get('messages')
            .then(async res => {
                if (!res.ok) return;

                setMsgs((await res.json()).count);
            });
    }, [])

    return (
        <div className="p-4">

            <div className="p-4 rounded-md shadow-lg border max-w-lg text-sm my-4">
                <div className="flex items-center">
                    <div className="flex-1"><Logo className="w-16" /></div>
                    <p className="opacity-50">&copy; dataclash.net 2024</p>
                </div>
                <div>
                    <p>Dataclash Internet Relay Chat</p>
                    
                    <p>Server ver. {apiVer}</p>
                    <p>Client ver. {VERSION}</p>
                </div>

                <table className="w-full" cellPadding={4}>
                    <tbody>
                        <tr>
                            <td>Users</td>
                            <td className="text-right">{users ? users.length.toString() : '--'}</td>
                        </tr>
                        <tr>
                            <td>Messages</td>
                            <td className="text-right">{msgs != undefined ? msgs.toString() : '--'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}