import { useContext } from "react";
import { Theme, ThemeContext } from "../contexts/theme.context";

export default function Logo({className}: {
    className?: string;
}) {
    const {theme} = useContext(ThemeContext);

    return (
        <div className={className}><img src="/logo192.png" alt="Logo" className={theme === Theme.DARK ? 'invert' : undefined} /></div>
    );
}